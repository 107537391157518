import React from 'react';
import { Text } from '@uremont/erp-ui';

import { dataTestId } from '@shared/constants/dataTestId';
import { InputsForm } from '@shared/components/InputsForm/InputsForm';

import { InputsFormProps } from '@shared/components/InputsForm/types';
import styles from './styles.module.scss';

export const CollaborateView = ({
  errors,
  values,
  handleChange,
  handleSubmit,
  dragOver,
  fileDrop,
  onChangeFileInput,
  deleteFile,
  handleBlur,
  touched
}: InputsFormProps) => {
  return (
    <div data-testid={dataTestId.COLLABORATE_VIEW}>
      <Text className={styles.title} variant='headline-L'>
        Хотите сотрудничать?
      </Text>
      <Text className={styles.text} variant='body-tight'>
        Заполните форму ниже, и мы обязательно свяжемся с вами
      </Text>
      <InputsForm
        deleteFile={deleteFile}
        dragOver={dragOver}
        errors={errors}
        fileDrop={fileDrop}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isCommentInput
        isInputFile
        isInputMail
        isInputName
        isInputPhone
        isShowCheckbox
        onChangeFileInput={onChangeFileInput}
        touched={touched}
        values={values}
      />
    </div>
  );
};
