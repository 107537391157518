// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PO9pdtMQ_HAqaSA5_JRA{margin-bottom:8px}.W5Ulr3141BPPYi5JuMw3{line-height:20px}`, "",{"version":3,"sources":["webpack://./src/pages/Main/lib/Collaborate/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,gBAAA","sourcesContent":[".title {\n  margin-bottom: 8px;\n}\n\n.text {\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `PO9pdtMQ_HAqaSA5_JRA`,
	"text": `W5Ulr3141BPPYi5JuMw3`
};
export default ___CSS_LOADER_EXPORT___;
