import cx from 'classnames';
import { uremontLinks } from '@shared/constants/links';
import charity from './assets/charity.png';
import { CharityProps } from './types';
import styles from './styles.module.scss';

export const Charity = ({ small }: CharityProps) => (
  <a
    href={uremontLinks.charity}
    className={cx(styles.banner, {
      [styles.banner_small]: small
    })}
  >
    <img
      alt='charity'
      src={charity}
      className={cx(styles.banner_icon, {
        [styles.banner_icon_small]: small
      })}
    />
    <div
      className={cx(styles.banner_content, {
        [styles.banner_content_small]: small
      })}
    >
      Благотворительный фонд
    </div>
  </a>
);
