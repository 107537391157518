import React from 'react';
import Slider from 'react-slick';
import cx from 'classnames';
import { Loyalty } from 'src/features/AppLayout/components/Header/lib/SliderBanner/Loyalty/Loyalty';
import { Charity } from 'src/features/AppLayout/components/Header/lib/SliderBanner/Сharity/Charity';
import { Raffle } from 'src/features/AppLayout/components/Header/lib/SliderBanner/Raffle/Raffle';
import { SETTINGS } from './constants';
import styles from './styles.module.scss';
import { SliderBannerProps } from './types';

export const SliderBanner = ({ small }: SliderBannerProps) => (
  <Slider {...SETTINGS}>
    <div
      className={cx(styles.slide, {
        [styles.slide_small]: small
      })}
    >
      <Loyalty small={small} />
    </div>
    <div
      className={cx(styles.slide, {
        [styles.slide_small]: small
      })}
    >
      <Charity small={small} />
    </div>
    <div
      className={cx(styles.slide, {
        [styles.slide_small]: small
      })}
    >
      <Raffle small={small} />
    </div>
  </Slider>
);
