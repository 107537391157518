import { EMPTY_ARRAY, EMPTY_STRING } from 'src/shared/constants/fallbacks';

export const defaultValuesCollaborateForm = {
  name: EMPTY_STRING,
  phone: EMPTY_STRING,
  email: EMPTY_STRING,
  agreement: false,
  comment: EMPTY_STRING,
  file: Array.from(EMPTY_ARRAY)
};
