import React from 'react';

import { Banners } from 'src/pages/Main/lib/Banners';
import { Collaborate } from 'src/pages/Main/lib/Collaborate';
import { FAQ } from 'src/pages/Main/lib/FAQ';
import { Search } from 'src/pages/Main/lib/Search';
import { dataTestId } from 'src/shared/constants/dataTestId';

export const MainView = () => {
  return (
    <div data-testid={dataTestId.MAIN_VIEW}>
      <Search />
      {/* <Catalog /> TODO: Временное скрытие блока, в будущем его снова включат*/}
      {/*{<RecommendedPurchase /> TODO: Временное скрытие блока, в будущем его снова включат*/}
      <Banners />
      {/* <TireSelection /> TODO: Временное скрытие блока, в будущем его снова включат*/}
      {/*<Recommendations TODO: Временное скрытие блока, в будущем его снова включат*/}
      {/*  caption='Вы недавно смотрели'*/}
      {/*  isLoading={false} // TODO: когда в компонент будет интегрировано api, заменить false*/}
      {/*  products={recentlyViewedMockData}*/}
      {/*/>*/}
      <Collaborate />
      <FAQ />
    </div>
  );
};
