import React, { useState } from 'react';

import { InputsFormValues, useInputsForm } from 'src/shared/hooks/use-inputs-form';
import { defaultValuesCollaborateForm } from 'src/pages/Main/lib/Collaborate/lib/constants/constants';
import { collaborateFormSchema } from 'src/pages/Main/lib/Collaborate/lib/constants/schema';
import { useCollaborateStore } from 'src/shared/model/Store';
import { EMPTY_STRING } from 'src/shared/constants/fallbacks';
import { useFormik } from 'formik';
import { CollaborateView } from './CollaborateView';

export const Collaborate = () => {
  const [postCollaborateData] = useCollaborateStore(state => [state.postCollaborateData]);
  const [initialValues, setInitialValues] = useState<InputsFormValues>(
    defaultValuesCollaborateForm
  );
  const formik = useFormik<InputsFormValues>({
    initialValues,
    validationSchema: collaborateFormSchema,
    onSubmit: (values: InputsFormValues) => {
      setInitialValues(values);
      handlePostCollaborateData();
    }
  });
  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    resetForm,
    handleBlur,
    setFieldValue,
    touched
  } = formik;

  const handlePostCollaborateData = () => {
    const serverData = {
      full_name: values.name || EMPTY_STRING,
      phone: values.phone || EMPTY_STRING,
      email: values.email || EMPTY_STRING,
      comment: values.comment || EMPTY_STRING,
      file: values.file ? values.file[0] : null
    };

    postCollaborateData({ serverData, resetForm });
  };

  const { dragOver, fileDrop, onChangeFileInput, handleDeleteFile } = useInputsForm({
    values,
    setFieldValue,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    touched
  });

  return (
    <CollaborateView
      deleteFile={handleDeleteFile}
      dragOver={dragOver}
      errors={errors}
      fileDrop={fileDrop}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      onChangeFileInput={onChangeFileInput}
      touched={touched}
      values={values}
    />
  );
};
