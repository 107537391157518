import cx from 'classnames';
import { uremontLinks } from '@shared/constants/links';
import loyalty from './assets/loyalty.png';
import { LoyaltyProps } from './types';
import styles from './styles.module.scss';

export const Loyalty = ({ small }: LoyaltyProps) => (
  <a
    href={uremontLinks.loyalty}
    className={cx(styles.banner, {
      [styles.banner_small]: small
    })}
  >
    <img alt='loyalty' src={loyalty} />
    <div
      className={cx(styles.banner_content, {
        [styles.banner_content_small]: small
      })}
    >
      <div>Программа</div>
      <div>лояльности</div>
    </div>
  </a>
);
