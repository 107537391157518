import React from 'react';
import { Text } from '@uremont/erp-ui';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { dataTestId } from '@shared/constants/dataTestId';
import BannerFirst from '@assets/banners/Banner-first.png';
import BannerFirstMobile from '@assets/banners/Banner-first-mobile.png';
import { uremontLinks } from '@shared/constants/links';

import { BannersViewProps } from './types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './styles.module.scss';

// TODO - раскомметировать изменения, при возвращении баннера "Автомасла" в слайдер баннера

export const BannersView = ({ settings }: BannersViewProps) => {
  return (
    <div data-testid={dataTestId.BANNERS_VIEW}>
      <Slider {...settings}>
        <Link target='_blank' to={uremontLinks.customerDashboard}>
          <div className={cx(styles.slide, styles.slide_one)}>
            <div className={styles.slide_textWrapper}>
              <Text className={styles.slide_title} variant='headline-L'>
                Максимальная выгода
              </Text>
              <Text className={styles.slide_subTitle} variant='body-tight'>
                Экономьте <span>до 30% на обслуживании</span> автомобиля с программой лояльности
                Uremont!
              </Text>
              <div className={styles.slide_textWrapper_iconContainer}>
                <img alt='auto' src={BannerFirstMobile} />
              </div>
            </div>
            <img alt='auto' className={styles.slide_img} src={BannerFirst} />
          </div>
        </Link>
        {/* <Link target='_blank' to={uremontLinks.silkOil}>
          <div className={cx(styles.slide, styles.slide_two)}>
            <div className={styles.slide_textWrapper}>
              <Text className={styles.slide_title} variant='headline-L'>
                Оригинальные масла от Uremont
              </Text>
            </div>
          </div>
        </Link> */}
      </Slider>
    </div>
  );
};
