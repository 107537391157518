import { ArrowSlider } from 'src/shared/components/ArrowSlider';

import styles from './styles.module.scss';

export const bannersSettings = {
  className: styles.slider,
  dots: false,
  infinite: true,
  arrows: true,
  nextArrow: <ArrowSlider arrowDirection='right' />,
  prevArrow: <ArrowSlider arrowDirection='left' />,
  speed: 2300,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000
};
