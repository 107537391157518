import { HeaderMenu, IconedText } from '@uremont/ui-kit';
import {
  NotesPen,
  Settings,
  Map,
  Security2,
  Security1,
  Security,
  Car,
  ShareCircle,
  Service,
  SecurityHome
} from '@uremont/ui-icons-v2';
import { uremontLinks } from '@shared/constants/links';
import styles from './styles.module.scss';

// TODO - раскомметировать изменения, при возвращении пункта "Автомасла" в меню хедера

export const MenuItems = () => (
  <>
    <HeaderMenu title='Обслуживание автомобиля'>
      <a className={styles.headerLink} href={uremontLinks.newBid}>
        <IconedText icon={NotesPen} iconSize={24} textType='bodyTightRegular'>
          Заявка на обслуживание
        </IconedText>
      </a>
      <a className={styles.headerLink} href={uremontLinks.autoparts}>
        <IconedText icon={Settings} iconSize={24} textType='bodyTightRegular'>
          Автозапчасти
        </IconedText>
      </a>
      {/* <a className={styles.headerLink} href={uremontLinks.silkOil} rel='noreferrer' target='_blank'>
        <IconedText icon={GasStation} iconSize={24} textType='bodyTightRegular'>
          Автомасла
        </IconedText>
      </a> */}
      <a className={styles.headerLink} href={uremontLinks.map}>
        <IconedText icon={Map} iconSize={24} textType='bodyTightRegular'>
          Автосервисы на карте
        </IconedText>
      </a>
    </HeaderMenu>
    <HeaderMenu title='Страхование'>
      <a className={styles.headerLink} href={uremontLinks.insurance}>
        <IconedText icon={Security2} iconSize={24} textType='bodyTightRegular'>
          ОСАГО
        </IconedText>
      </a>
      <a className={styles.headerLink} href={uremontLinks.insuranceKasko}>
        <IconedText icon={Security1} iconSize={24} textType='bodyTightRegular'>
          КАСКО
        </IconedText>
      </a>
      <a className={styles.headerLink} href={uremontLinks.insuranceEipoteka}>
        <IconedText icon={SecurityHome} iconSize={24} textType='bodyTightRegular'>
          Ипотека
        </IconedText>
      </a>
      <a className={styles.headerLink} href={uremontLinks.insuranceAgents}>
        <IconedText icon={Security} iconSize={24} textType='bodyTightRegular'>
          Стать страховым агентом
        </IconedText>
      </a>
    </HeaderMenu>
    <HeaderMenu title='Партнерство'>
      <a
        className={styles.headerLink}
        href={uremontLinks.autoparks}
        rel='noreferrer'
        target='_blank'
      >
        <IconedText icon={Car} iconSize={24} textType='bodyTightRegular'>
          Корпарки
        </IconedText>
      </a>
      <a className={styles.headerLink} href={uremontLinks.autoparts}>
        <IconedText icon={Settings} iconSize={24} textType='bodyTightRegular'>
          Автозапчасти
        </IconedText>
      </a>
      <a className={styles.headerLink} href={uremontLinks.insuranceAgents}>
        <IconedText icon={Security} iconSize={24} textType='bodyTightRegular'>
          Стать страховым агентом
        </IconedText>
      </a>
      <a className={styles.headerLink} href={uremontLinks.franchising}>
        <IconedText icon={ShareCircle} iconSize={24} textType='bodyTightRegular'>
          Франшиза
        </IconedText>
      </a>
      <a className={styles.headerLink} href={uremontLinks.registerService}>
        <IconedText icon={Service} iconSize={24} textType='bodyTightRegular'>
          Для автосервиса
        </IconedText>
      </a>
    </HeaderMenu>
  </>
);
