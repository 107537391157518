import * as Yup from 'yup';

import * as expect from '@utils/validationExpect';
import * as messages from '@utils/validationMessages';
import * as patterns from '@utils/validationPatterns';

import { CollaborateProps } from '../../ui/types';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const collaborateFormSchema: Yup.SchemaOf<CollaborateProps> = Yup.object()
  .shape({
    name: Yup.string()
      .matches(patterns.fullName, messages.correctName)
      .max(50, messages.maxLength(50))
      .required(messages.required),
    email: Yup.string()
      .nullable()
      .matches(patterns.emailRegex, messages.correctEmail)
      .email(messages.correctEmail)
      .max(255, messages.maxLength(255))
      .required(messages.required),
    phone: Yup.string()
      .test('phone', messages.correctPhone, expect.phoneNumber)
      .required(messages.required),
    comment: Yup.string().max(300, messages.maxLength(300)).required(messages.required),
    agreement: Yup.boolean().oneOf([true], messages.required)
  })
  .required(messages.required);
